export default {
    FACEBOOK: 'facebook',
    INSTAGRAM: 'instagram',
    TELEGRAM: 'telegram',
    TWITTER: 'twitter',
    VK: 'vk',
    YOUTUBE: 'youtube',
    MEETUP: 'meetup',
    OTHER: 'other',
};
